
/* Livesearch Styles */
#LSResult {
  position:absolute;
  width:188px;
  color:#000;
  list-style:none;
  background-image:url(../images/navsubbg.png);
  height:150px;
  z-index:9000;
  overflow-y:auto;
  -moz-box-shadow: 0 3px 10px;
  -webkit-box-shadow: 0 0 10px;
}
#LSHighlight {
  background-color: #EBEBEB;
  border-top: 2px solid #BEBEBE;
  border-bottom: 2px solid #BEBEBE;
}

#LSHighlight small {
  color: #555;
}

#LSResult:hover #LSHighlight {
  /*background-color: white;
  border-color: white;*/
}

.LSRes {list-style-type:none; margin:0 0 4px 0; font-size:.857em;}

.LSRow {
  text-align: left;
  list-style-type:none;
  padding: 2px 15px;
}

.LSRow:hover {
  background-color: #C1BC00;
}

.LSRow a {
  /*font-weight: bold;*/
  text-decoration:none;
}

.LSRow a:hover {color:#000; text-decoration:none;}

.LSHeader {
  color:#000;
  padding: 0 15px;
}

#search_members { background-image:url(../images/icon_searchmag.gif); background-repeat:no-repeat; background-position: left; padding: 3px 4px 3px 22px; margin-left:0;}
.members_list{
  list-style:none;
  border: dotted black 1px;
  margin: 5px 0 0 182px;
  padding: 5px;
  width: 343px;
}

#members_list li:hover{
  background-color:#CCC;
  cursor:pointer;
}
