
.mce-content-body {
  .player-profile.spotlight {
    border: 2px solid #000000;
    padding: 6px;
    margin-top:10px;
    position: relative;
  }

  .player-profile.spotlight > .sc-label {
    position: absolute;
    left: -2px;
    top: -18px;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    background-color: #000000;
    padding: 0px 7px;
  }

  /*
  .sc.player-spotlight::after { content: "Player Spotlight ShortCode"; }
  .sc.player-video::after { content: "Player Video ShortCode"; }
  */
  .scouting-comments .mobile-collapse-container { font-size: 13px; }
  .btn-primary.mobile-expand { display:none; }



  /* Player Helpers*/
  body{
    counter-reset: player;
  }
  .analysis-list {
    margin: 15px 0;
  }

  .analysis-list > table .player-img > a:before {
    content: counter(player);
    display: block;
  }
  .player-analysis {
    border: 1px dashed #cecece;
    font-size: .9em;
    width:100%;
    counter-increment: player;
    margin-bottom: 9px;
  }
  .player-analysis td {
    display: table-cell;
    padding: 9px 0;
  }
  .player-analysis h3 {
    text-transform: uppercase;
    font-family: "myriad-pro-condensed", sans-serif;
    color: #ed1c24;
    font-size: 1.9em;
    margin-bottom: 3px;
  }
  .player-analysis h3 span {
    display: block;
    color: #000;
    font-size: .8em;
  }
  .player-analysis p {
    line-height: 1.4em;
    text-align: left;
  }
  .player-analysis p:last-child {
    margin-bottom: 0;
  }
  .player-analysis .player-img {
    text-align: center;
    width: 150px;
  }
  .player-analysis .player-img > a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    width: 90%;
  }
  .player-analysis .player-img > a:before {
    background: #17181b;
    background: -moz-linear-gradient(top, #17181b 50%, #000000 51%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #17181b), color-stop(51%, #000000));
    background: -webkit-linear-gradient(top, #17181b 50%, #000000 51%);
    background: -o-linear-gradient(top, #17181b 50%, #000000 51%);
    background: -ms-linear-gradient(top, #17181b 50%, #000000 51%);
    background: linear-gradient(to bottom, #17181b 50%, #000000 51%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#17181b', endColorstr='#000000', GradientType=0);
    text-align: center;
    color: #fff;
    font-family: "myriad-pro-condensed", sans-serif;
    font-weight: 700;
    font-size: 18px;
    width: 24px;
    position: absolute;
    padding: 3px 0;
    border-radius: 0 0 2px 0;
  }

  .no-count .player-img a:before{
    display:none !important;
  }



  //.red {color:#ea1d2c !important;}

  .alert-header {font-family: "myriad-pro-condensed",sans-serif; font-weight: 900; color: #ea1d2c; font-size: 25px; text-transform: uppercase; display: inline-block; line-height: 25px;}


  .player-spotlight-plugin{
    display:none;
  }

  .player-spotlight-wrap  { background-color: #cecece; border:2px dashed #ff3333; }
  .player-spotlight-wrap .mce-holder { padding: 25px; }
  .player-spotlight-wrap .mce-holder table tr td {
    color: #FF3333;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }

  /* Short Code */
  .sc { display:block; width:100%; background-color:#dedede; padding:25px 25px 15px 25px; border:2px dashed #ff3333;  position: relative; font-size: 12px; box-sizing: border-box; margin-bottom:15px;}
  .sc .sc-inner { opacity: 0.4; margin-top: 14px; }
  .sc::after { position: absolute;
    right: -2px;
    top: -2px;
    font-size: 10px;
    text-transform: uppercase;
    color: white;
    background-color: #ff3333;
    padding: 0px 5px;
  }

  .sc .sc-label{
    position: absolute;
    right: -2px;
    top: -2px;
    font-size: 10px;
    text-transform: uppercase;
    color: white;
    background-color: #ff3333;
    padding: 0px 5px;
  }

}


.sticky-tools {
  position: fixed;
  top: 0;
  z-index: 100000;
}
