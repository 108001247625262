
/*CollapsiblePanel*/
.ContainerPanel {
  margin-bottom: 15px;
}

.ContainerPanel input {
  border: none;
  width: auto;
}


.collapsePanelHeader {
  width:100%;
  height:30px;
  background-color: #2d3544;
  color:#FFF;
  font-size: 1.143em;
  font-family: Georgia, "Times New Roman", Times, serif;
  border: 1px solid #b1b1b1;
}

.HeaderContent, .ArrowExpand, .functionbuttons {float:left;}

.HeaderContent {
  padding: 3px 0 0 5px;
}
.Content {
  margin: 0;
  padding: 0;
  clear:both;
}
.ArrowExpand {
  background-image: url(../images/button_expand.gif);
  width:12px;
  height:12px;
  margin:8px 5px 0 5px;
}
.ArrowExpand:hover {
  cursor:pointer;
}
.ArrowClose {
  background-image: url(../images/button_collapse.gif);
  width:12px;
  height:12px;
  float:left;
  margin:8px 5px 0 5px;
}
.ArrowClose:hover {
  cursor:pointer;
}

.Content tr.tablehead {
  background-color: #fff;
  color: #171C29;
}

.Content table {
  border:none;
  padding-bottom: 0;
  margin:0;
  padding: 0;
}




/*SMALL TABS*/
#small-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-right: 30px;
  li {
    display: block;
    &:hover {

      a { background-color: darken($primary, 1); color: white; }
    }
    a {
      background-color: #f4f4f4;
      border-radius: 3px 3px 0 0;
      margin-right: 0;
      margin-bottom: 5px;
      padding: 10px 14px;
      color: black;
      text-decoration: none;
      display: block;
    }
  }

  li.active  {
    a {
      background-color: $primary;
      color: white;
      font-weight: bold;
    }
  }
}

/*TABS*/
#tabs {
  display: flex;
  width: 100%;
  @media (max-width: $screen-sm) {
    display: block;
  }
  .tab {
    padding: 0;
    width: 100%;
    //background-color: #f5f5f5;
  }
}

.tabs {
  width: 100%;
  .tab-holder {
    flex: 1;
  }

  .section-title {
    background-color: #5c636a;
    color: white;
    text-transform: uppercase;
    font-size: 17px;
    padding: 5px 9px;
    h4 {
      margin-bottom: 0;
      font-size: 1em;
    }
  }

  .section-panel {
    background-color: #f5f5f5;
    padding: 16px;
    margin-bottom: 25px;
  }


}