
/*HEADER*/

#header {
  //height: 104px;
  //background-repeat: repeat-y;
}

#headerleft {
  margin: 35px 0 25px 0;
}

#headermiddle {
  text-align: center;
  margin: 8px 0 0 0;
}

#headerright table {
  height:75px;
  margin: 0;
  font-size: .786em;
  border: none;
  padding: 0 0 5px 0;
}

#headerright table td {
  vertical-align:bottom;
  padding: 0;
  text-align: right;
}

#headerright table tr:hover {
  background-color: #fff;
}

#headerright a:link, #headerright a:visited {
  color: #171c29;
  text-decoration: underline;
}

#headerright a:hover {
  color:#d06f1a;
}

.username { font-weight:bold;}

#greenbar {
  background-color:#c1bc00;
  height: 10px;
  /*margin-left: 20px;*/
}


#navholder {
  //background-image:url(../images/navbackground.jpg);
  //background-repeat: repeat-x;
  //height: 38px;
  //padding-top:14px;
}

/*MAINNAV* this can be deleted when qmenu is in*/
#mainnav {
  height: 44px;
  width: auto;
  margin: 0;
  padding: 0;
  border: none;
}

#mainnav td {
  padding: 0;
}

#mainnav tr:hover {
  background-color: transparent;
}

#navholder a.onpage.qmactive {background-color:#fff; background-image:none;}
/*---------EOF HEADER-------------*/


div#navholder {
  border-bottom: 1px solid #cecece;
  .navbar-collapse{
    margin-left: 0;
  }
}

div#header {
  margin-top: 0;
  .header-top-links {
    display: flex;
    justify-content: flex-start;
    > div {
      margin: 0 12px;
    }
  }
}

.navbar {
  padding:0;


  .navbar-nav {
    margin-top: 25px;
    flex: 1;
  }
  .nav-item {
    background-color: #d7d7d7;
    color: black;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 7px;
    &.onpage {
      background-color: $primary;
      a.nav-link {
        color: white;
      }
    }
    a.nav-link {
      text-decoration: none;
      color:black;
    }


    .dropdown-item {
      text-decoration: none;
      font-size: .9em;
    }
  }

}

.cms-logo img {
  width: 123px;
  padding-bottom: 5px;
  margin-left: 15px;
}

.header-link {
  font-size: .85em;
  line-height: 24px;
}