#player_search_results .item,
#event_search_results .item,
#customer_search_results .item {
  background-color: white;
  padding: 4px 14px;
  border: 1px solid #d1d1d1;
  margin: 4px 0;
  font-size: 14px;
  cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
}

