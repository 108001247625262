@import "events/media";
@import "events/schedule";


div#ModuleMenuHeader {
  justify-content: flex-start;
  display: flex;
}

.list-filters {
  margin: 25px 0;
  .keyword-filter{
    display: flex;
    margin-right: 50px;
    flex: 1;
    input {
      max-width: 300px;
    }
  }


  .filter-row{
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
    }
    label { white-space: nowrap; margin-right: 7px; }
  }

  .list-filters-body {
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .form-list {
    margin-bottom: 3px;
    li {
      float: left;
      margin-right: 13px;
    }
    .form-label {
      display: block;
      float: none;
      width: 25%;
    }
    &::after {
      clear: both;
      zoom: 1;
      display: block;
      content: " ";
    }
  }

  .btn-link {
    padding: 5px 0;
    display: flex;
    color: black;
    text-decoration: none;
    &:focus {
      box-shadow: none;
    }

    > .toggleIcons {
      margin-right: 10px;
      .toggle-closed{
        display: none;
      }

      .toggle-open{
        display: block;
      }
    }
  }
  .btn-link.collapsed {
    > .toggleIcons {
      .toggle-closed{
        display: block;
      }

      .toggle-open{
        display: none;
      }
    }
  }

}

.btn-visibility {
  //display: flex;
  //flex-wrap: wrap;
  & > div {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .ui-state-default {
    display: flex;
    padding: 3px 7px;
    align-items: center;
    min-width: 146px;
    input {
      margin: 0 5px;
    }
  }
}


.age_group_option {

  .form-floating > .form-select {
    padding-top: 1.325rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control, .form-floating > .form-select {
    height: calc(2.8rem + 2px);
    line-height: 1.25;
  }
}