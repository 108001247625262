
$list-background-color: #f1f1f1;

.data-list
{
  article.data-item {
    background-color: $list-background-color;
    padding: 9px 13px;
    border: 1px solid darken($list-background-color, 5);
    margin: 6px 0;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
    line-height: 1.2em;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
      background-color: darken($list-background-color, 5);
    }
    @media (max-width: $screen-sm) {
      display: block;

    }
  }
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    color: #545454;
  }

  p {
    margin-bottom: 0;
    font-size: .9em;
  }

  .item-id {
    margin-right: 15px;
    font-size: .8em;
    padding: 3px;
    border: 1px solid #cecece;
    color: #949494;
  }

  .item-main {
    flex: 1;
  }

  .item-type {
    margin: 0 20px;
    flex: 0 0 89px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .item-toolbar {
    display: flex;
    flex: 0 0 400px;
    align-items: center;
    > div {
      margin-right: 15px;
      flex: 1;
    }
    .col-status{
      flex: 0 0 120px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      .alert{
        font-size: 14px;
        padding: 5px 16px;
        white-space: nowrap;
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        display: block;
      }
    }
  }
  .item-status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    cursor: pointer;
    &[data-status="on"]{
      color: #699b69;
    }
    &[data-status="off"]{
      transform: rotate(180deg);
      color: #db6e75;
    }
  }
  .edit-col {
    margin-left: auto;
  }
}
