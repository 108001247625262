/*LINKS LISTS TYPOGRAPHY*/
@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');
a:link, a:visited {
  color: #171c29;
  text-decoration: underline;
}

body {
  font-family:"Proxima Nova", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

a:hover {
  color:$primary;
}

h1, h2, h3, h4 {font-family:"Proxima Nova", Helvetica, Arial, sans-serif;}

h1 {
  font-size:1.8573em;
  margin: 5px 0;
  font-weight:bold;
}

h2 {
  font-size: 1.429em;
  margin: 5px 0;
}

h3 {
  font-size:1.286em; margin:0;}

//.green {color: $primary;}
//.blue {color: #2d3544;}
//.orange{color: #D06f1a;}

.small {font-size:.8em;}
/*--------EOF LINKS,LISTS,TYPOGRAPHY---------*/
