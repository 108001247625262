
/*FOOTER*/
#footer {
  color: #fff;
  background-color: $primary;
  margin-top: 0;
  height: 35px;
  font-size: .786em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
}

.footer-wrapper{
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 35px;
}

#footerright {
  text-align: right;
}

#footer div {
  text-align:center;
}

/*--------EOF FOOTER--------------*/
