/*LOGIN PAGE*/

#dvLogin {
  padding:15px; -moz-border-radius:15px; -webkit-border-radius:15px; border-radius:15px; background-color:#bcbaba;
  max-width: 700px;
  margin: auto;
}

#dvLogin table {border:none; margin-bottom:0; padding-bottom:0;}
#dvLogin tr:hover {background-color:transparent;}








/* LOGIN - MODULE: FAL DB */
input[type='button'] { cursor: pointer; }
#practice-area-edit input[type='text'], #practice-area-edit textarea{ width: 350px; }
#practice-area-edit input[type='submit'], #practice-area-edit input[type='button']{ width: 100px; cursor:pointer; }
#practice-area-edit select{ width: 200px; }
#practice-area-edit li ul li, #practice-area-edit li{	line-height:normal; }

/* LOGIN - MODULE: MEMBERS DB */
/*#tabs span{ display: block; }*/
#frmMembers input[type='text'], #practice-area-edit textarea{ width: 350px; }
#frmMembers input[type='checkbox']{ width: auto; margin-right: 5px; }
#frmMembers input[type='submit'], #practice-area-edit input[type='button']{ width: 100px; cursor:pointer; }
#frmMembers select{ width: 200px; }
#frmMembers li ul li, #practice-area-edit li{	line-height:normal; }