

/*SIDE BAR*/
#sidebarholder {
  background-color: #F7F9EA;
  margin-bottom: 10px;
}

#sidebar {
  padding: 0 24px;
}

#sidebar p {
  font-weight: bold;
  font-size: .929em;
}

#sidebar h2 {
  margin-bottom: 0;
}

.teamnum {
  font-family: Georgia, "Times New Roman", serif;
  font-size: 1.286em;
  font-weight: normal;
}

.sidebarlist {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 7px;
}

.sidebarlist li {
  list-style-type: none;
  line-height: 23px;
}

.editlink {
  float:right;
  font-size: 0.65em;
  padding-top: 6px;
}



/*CMS STYLES*/
.right-tools-fixed {
  position:fixed; top:10px; background-image:url(../images/whitetrans.png); z-index:9999; width:190px;
}

#sub-tools {margin-top:30px; margin-bottom:20px;}

#ModuleMenuCollapse {
  min-width: 300px;
  z-index: 5;
  box-shadow: 2px 4px 11px rgba(0, 0, 0, .17);
}

.collapse {font-size:.929em; margin-left:3px;}
#site-select {float:right; width:220px;}
#site-select select {font-size:1em; margin-right:0; margin-bottom:3px;}

#search-input {border: 2px solid #9b9b99; height:16px; width:160px; background-image:url(../images/icon_searchmag.gif); background-repeat:no-repeat; background-position: left; padding: 3px 4px 3px 22px; margin-left:0;}
#livesearch {
  background-image:url(../images/icon_searchmag.gif);
  background-repeat:no-repeat;
  background-position: left;
  margin-left:0;
}

#right-tools {
  margin-top:20px;
}
#searchform {margin-bottom:15px;}

#cms-toolbox {
  border: none; // 2px solid #9b9b99;
  padding:15px 15px 25px;
  border-radius: 7px;
  background-color: #ebebeb;
  h2 {
    text-align:center;
  }
}
#cms-toolbox
#tools {padding-left:0; margin: 0; font-weight: bold;}
#tools a {font-size:.893em; text-decoration:none;}
#tools li {list-style-type:none;}
#save-page {float:left;}
#delete-page {float:right;}

.buttonPageEdit {
  float: right;
  position: absolute;
  right: 0;
  top: 15px;
}

