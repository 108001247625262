

header.module-header {
  background-color: #e8f1f6;
  position: relative;
  padding: 17px 0;

  h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: bold;
    color: #0066a1;
    flex: 1;
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
  }
}

#right-tools {
  //position: absolute;
  right: 0;
  top: 0;
  margin-top:0;
  position: relative;
}

#ModuleMenuCollapse {
  position: absolute;
}