/* FORUM CSS */

#forum ul{ margin:0; padding:0; margin-bottom: 0px; line-height:normal; list-style: none; }
#forum li{ margin:0; padding:0; margin-bottom: 0px; line-height:normal; list-style: none; }
#forum .category{ list-style:none; border: 1px solid #999; margin-bottom: 10px; position:relative; }

#forum .cat_head { padding: 10px; line-height: 20px; }
#forum .topic{ font-size: 12px; }
#forum .cat_name{ font-size:16px; }
#forum .cat_desc{ font-size:12px; color:#999; }
#forum li li { margin-bottom: 0px; padding:3px; vertical-align:top; }
#forum .posts { *display: inline; display: inline-block; zoom:1; height: 25px; vertical-align: top;}
#forum .time_stamp{ font-size:9px; color:#666; *display: inline; zoom: 1; display: inline-block; width: 250px; margin-left: 10px; height: 15px; vertical-align: top;}
#forum .topic_info{ float:right; text-align: right; width: 320px; vertical-align: top; }
#forum .odd{ background-color:#DDD; padding: 10px; }
#forum .even{ background-color:#EEE; padding: 10px; }
#forum #topic_main .topic { width: 100%; }
#forum #topic_main .post_left_td { background-color:#FDFFDC; vertical-align:top; border-right: 1px solid #999; width: 200px;}
#forum #topic_main .topic .post_left { background-color:#FDFFDC; position:relative; min-height: 100px; width: 200px; vertical-align:top; text-align:left; padding: 20px 5px 5px 20px; }
#forum #topic_main .topic .post_content{ padding: 10px; vertical-align:middle; }
#forum a:link{ color:#263E5E; text-decoration: none; }
#forum a:hover{ color:#999; text-decoration:underline; }
#forum .post_row {border: 1px; border: 1px solid #CCC; }
#forum p { text-align: left; margin: 0px; }
#forum .row_num { font-size:11px; position:absolute; color:#555; background-color:#DDD; left:0px; top: 0px; padding: 0px 2px 0px 2px; }
#forum .row_actions { font-size:11px; position:absolute; color:#555; background-color:#FDFDEC; left:0px; bottom: 0px; border: dotted 1px #999; padding: 0px 2px 0px 2px; }
#forum .cat_delete { font-size:11px; position:absolute; right:0px; top: 5px; padding: 0px 2px 0px 2px; }
#forum input { width: 300px; }
#forum .topic {border:none; }
#forum td:hover, #forum tr:hover  { background-color: transparent; }
/* END FORUM CSS */