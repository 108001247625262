
html, body {height: 100%;}

#wrapper {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding-bottom: 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

#columns {
  flex: 1;
}


body {
  color: black;
  margin: 0;
  padding: 0;
  padding-bottom: 35px;
  //position: fixed;
  //width: 100%;
  //height: 100%;
}

body.iframe {
  position: relative;
}

.right {float:right;}

.left {float:left;}


.showPageRight {
  position:relative;
  float:right;
  margin-right:17px;
  margin-top:15px;
}

.showPageLeft {
  position:relative;
  float:left;
}

/*===========================================*/

.pos-relative { position:relative; }