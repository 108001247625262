

#columns.container {
  #right {
    padding-top: 20px;
  }
}


/*ERRORS*/
#est_errors {
  margin-bottom: 15px;
  color: #D06F1A;
  display: none;
}

#mHolder {
  color:#FFF; -moz-border-radius: 10px; -webkit-border-radus:10px; border-radius:10px; width:250px; position:absolute; top:33%; left:33%; margin: auto auto; z-index:5000; background-image:url(../images/alert_bg.png); text-align:center; padding: 25px;

}

.LSRow small, #searchcontrols small {
  color: #aaa;
}
