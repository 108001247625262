
/*DEFAULT TABLES STYLES*/
table {
  border-right: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  padding-bottom:25px;

}

tr.tablehead {
  font-size: 1.08em;
  color: #fff;
  background-color: $primary;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

tr.row_even {
  background-color: #f4f4f4;
}

tr.tablehead:hover {
  background-color: #2d3544;
}

tr.alt, div.alt {
  background-color: #F7F9EA;
}

tr:hover {
  background-color: #cecece;
}

#tcal tr:hover, #email_preview tr:hover {
  background-color: transparent;
}

#tcal td:hover {
  background-color:#CCC;
}

table a:link, table a:visited {
  text-decoration: none;
}

table a:hover {
  text-decoration: underline;
  color: #171C29;
}



/*TREE MENUS*/
#tmenu0 {list-style-type:none; margin:0;}
#tmenu0 ul {list-style-type:none;}
#tmenu0 a {vertical-align:middle; text-decoration:none;}

/*FORM LISTS*/
.form-list {
  list-style-type:none;
  margin:0 0 25px 0;
  padding-left: 0;
  > li {
    margin-bottom:5px;
  }
  .checkbox {margin-right:15px;}
  .form-label {
    display:block;
    label {
      margin-bottom: 0;
    }
  }
}

.flexbox {
  display: flex;
}
