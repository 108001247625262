/*COLORS

GREEN: #C1BC00
DARK BLUE: #2D3544
ORANGE: #D06F1A
FONT COLOR: #171C29
TAN: F7F9EA
*/


$primary: #0066a1;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1400px
);


$screen-xs: 412px;
$screen-sm: 768px;
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;

$screen-xs-max: $screen-sm - 1;
$screen-sm-max: $screen-md - 1;
$screen-sm-min: $screen-xs + 1;
$screen-md-max: $screen-lg - 1;