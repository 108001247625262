
#tab-media {
  .dropzone {
    padding: 30px;
    background-color: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 9px;
  }

  .dz-default.dz-message {
    color: #999;
    font-size: 18px;
    text-align: center;
  }
}

.ajax-saving {
  text-align: center;
  color: lightgrey;
  font-family: Arial,sans-serif;
  padding: 5px;
  top: 250px;
  right:500px;
  position: absolute;
  display: none;
  -moz-opacity: 0;
  z-index: 1000;
}

