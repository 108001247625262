



.media-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  background-color: rgba(255,255, 255,.8);
  padding: 15px 11px 15px 0;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  position: relative;

  .handle{ padding: 15px; margin-right: 15px; cursor: pointer; }
  img {
    max-width: 100%;
    width: 125px;
    margin-right: 15px;
  }
  iframe {
    width: 125px;
    height: 125px;
  }
  .media-name {
    flex: 1;
    margin-right: 15px;
  }

  .delete {
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 11px;
  }

  .category {
    margin-left: 54px;
    flex: 0 0 50%;
  }
  .year {
    flex: 0 0 150px;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

}