.schedules {
  label {
    display: block;
  }


  .session-schedule-items .handle {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }


  .schedule-option {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 7px;
    -moz-border-radius: 7px;
    margin-top: 10px;
    margin-bottom: 30px;
    position: relative;

    .delete-option {
      position: absolute;
      right: 0;
      top: -9px;
      width: 22px;
      height: 23px;
      background-color: rgba(255, 255, 255, .7);
      border-radius: 13px;
      -moz-border-radius: 13px;
      border: 1px solid #dEdEde;
      cursor: pointer;
      box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    }
  }


  .delete-option:hover {
    background-color: #FFFFFF;
  }

  .option-name {
    flex: 1;
  }
  .delete-schedule .ui-icon{
    margin-left:3px;
    margin-top:3px;
  }

  .schedule-name {
    flex: 1;
  }

  .session-schedule-items {
    padding: 10px 0;
    label { display:block;}
    input { width:100%; }
    .price { width:100px; margin-right:10px; }
    .sku { width:200px; margin-right:10px; }
    .teams {
      flex: 1;
      display: flex;
      > div {
        flex: 1;
      }
    }
    .trash {
      cursor: pointer;
      width: 19px;
      height: 22px;
      text-align: center;
      float: right !important;
      margin-top: 13px;
    }

    ul.schedule-item-sortable > li{ margin-bottom: 10px; }
    .ui-sortable{ margin-left:0; list-style: none; }

  }

  .session-schedule-item {
    display: flex;
    grid-gap: 7px;
    width: 100%;
  }

  .schedule-item {
    padding: 6px;
    border-top: none;
    display: flex;
    align-items: center;
  }


  .schedule-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .schedule-header > div{

    margin-right: 10px;
  }

}





